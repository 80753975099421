<template>
  <div class="InfoPopup">
    <LLConfirmModalTransition v-if="lastPopup" :key="lastPopup.id">
      <LLConfirmModal class="InfoPopupModal">
        <div class="InfoPopupModal__content">
          <LLWysiwygOutput :text="lastPopup.message" />
        </div>
        <template #actions>
          <LLButton small secondary @click="hidePopup(lastPopup.id)">{{ $t('button_close') }}</LLButton>
        </template>
      </LLConfirmModal>
    </LLConfirmModalTransition>
  </div>
</template>

<script>
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import LLConfirmModalTransition from '@/components/common/LLConfirmModal/LLConfirmModalTransition.vue'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LLWysiwygOutput from '@/components/common/LLWysiwygOutput.vue'

export default {
  name: 'InfoPopup',
  components: { LLWysiwygOutput, LLButton, LLConfirmModalTransition, LLConfirmModal },
  computed: {
    infoPopupList() {
      return [...this.$store.getters['infoPopup/infoPopupList']]
    },
    lastPopup() {
      return this.infoPopupList[0]
    }
  },
  methods: {
    hidePopup(id) {
      this.$store.commit('infoPopup/REMOVE_INFO_POPUP', { id })
    }
  }
}
</script>

<style scoped lang="scss">
.InfoPopup {
  &Modal {
    &__content {
      width: calc(100vw - 10rem);
      max-width: 500px;
    }
  }
}
</style>
