import Vue from 'vue'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import Api from '@/services/api'
import studyMessages from '@/utils/modules/studyMessages'
const state = () => ({
  savedMessages: {},
  savingStates: {}
})

export default {
  namespaced: true,
  state: state(),
  getters: {
    savedMessage:
      (state) =>
      ({ questionId }) => {
        return state.savedMessages[questionId]
          ? new StudyConversationMessageModel({
              ...state.savedMessages[questionId],
              isSaving: state.savingStates[questionId] || false
            })
          : null
      },
    hasSendingMessage: (state) => {
      return Object.values(state.savingStates).some((value) => value)
    }
  },
  mutations: {
    SET_MESSAGE(state, { message }) {
      state.savedMessages = { ...state.savedMessages, [message.questionId]: message }
    },
    SET_MESSAGE_SAVING_STATE(state, { questionId, value }) {
      state.savingStates = { ...state.savingStates, [questionId]: value }
    },
    REMOVE_MESSAGE(state, { questionId }) {
      Vue.delete(state.savedMessages, questionId)
      Vue.delete(state.savingStates, questionId)
    },
    REMOVE_ALL(state) {
      state.savedMessages = {}
      state.savingStates = {}
    }
  },
  actions: {
    async sendMessage({ commit }, { message }) {
      commit('SET_MESSAGE', { message })
      commit('SET_MESSAGE_SAVING_STATE', { questionId: message.questionId, value: true })

      try {
        const { studyId, questionId, prospectId, pollType } = message
        const params = { studyId, questionId, prospectId, messageId: message?.id }

        const apiMethod = message.id
          ? Api.studies.updateConversationStream
          : Api.studies.sendConversationStream

        const result = await apiMethod({
          ...params,
          message: message.convertToApi()
        })
        commit('REMOVE_MESSAGE', { questionId })
        studyMessages.trigger(studyMessages.enums.CONVERSATION_STREAM_MESSAGE_UPDATE_PARSED, {
          message: StudyConversationMessageModel.parseFromApi({
            message: result.conversationMessage,
            studyId,
            questionId,
            prospectId,
            pollType
          })
        })
        return result
      } catch (e) {
        throw e
      } finally {
        commit('SET_MESSAGE_SAVING_STATE', { questionId: message.questionId, value: false })
      }
    }
  }
}
