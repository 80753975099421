<template>
  <div class="PollRankedEditorBody">
    <draggable
      v-model="computedSelectedValue"
      class="PollRankedEditorBody__list"
      v-bind="dragOptions"
      :disabled="locked"
      @start="drag = true"
      @end="onDragEnd"
    >
      <transition-group :duration="500" :name="!drag ? 'flip-list' : null">
        <div v-for="(v, i) in computedSelectedValue" :key="v.id" class="PollRankedEditorBody__list-item">
          <div class="PollRankedEditorBody__list-number">{{ i + 1 }}</div>
          <div class="PollRankedEditorBody__list-value">
            <div v-if="v.image" class="PollRankedEditorBody__image" @click.stop.prevent>
              <LLFileSlider
                :pre-large="largeThumbnails && !mediumThumbnails"
                :medium="largeThumbnails && mediumThumbnails"
                :files="[v.image]"
                :small="!largeThumbnails"
              ></LLFileSlider>
            </div>
            <div v-if="!hideText" class="PollRankedEditorBody__text">
              {{ v.text }}
            </div>
          </div>
          <div v-if="!locked" class="PollRankedEditorBody__list-buttons">
            <button class="PollRankedEditorBody__list-button" :disabled="i === 0" @click="moveBack(v)">
              <ArrowMiniIcon
                class="PollRankedEditorBody__list-button-icon PollRankedEditorBody__list-button-icon_back"
              ></ArrowMiniIcon>
            </button>
            <button
              class="PollRankedEditorBody__list-button"
              :disabled="i === computedSelectedValue.length - 1"
              @click="moveNext(v)"
            >
              <ArrowMiniIcon
                class="PollRankedEditorBody__list-button-icon PollRankedEditorBody__list-button-icon_next"
              ></ArrowMiniIcon>
            </button>
          </div>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { QuestionChoiceOptionModel } from '@/models/questionChoiceOption'
import LLFileSlider from '@/components/common/LLFileSlider.vue'
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
export default {
  name: 'PollRankedEditorBody',
  components: {
    LLFileSlider,
    ArrowMiniIcon,
    draggable
  },
  mixins: [screenCatcher],
  props: {
    values: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    locked: { type: Boolean, default: false },
    largeThumbnails: { type: Boolean, default: false },
    hideText: { type: Boolean, default: false }
  },
  data() {
    return {
      drag: false,
      selectedValue: null
    }
  },
  computed: {
    sortedValues() {
      return [...(this.values || [])]?.sort((a, b) => a.randomOrder - b.randomOrder)
    },
    mediumThumbnails() {
      return this.tailwindScreens.mobOnly
    },
    possibleOptionsObject() {
      const optionsObject = {}
      const optionModels = this.values.map((value) =>
        QuestionChoiceOptionModel.parseFromApi({ questionChoiceOption: value })
      )
      optionModels.forEach((option) => {
        optionsObject[option.id] = option
      })
      return optionsObject
    },
    computedSelectedValue: {
      set(values) {
        if (!this.$_.isEqual(this.selectedValue, values)) {
          this.selectedValue = values.map((option) => option.id)
        }
      },
      get() {
        return this.selectedValue?.length
          ? this.selectedValue?.map((optionId) => this.possibleOptionsObject[optionId])
          : [...this.sortedValues]
      }
    },
    dragOptions() {
      return {
        animation: 200,
        ghostClass: 'ghost'
      }
    }
  },
  watch: {
    value: {
      handler(value) {
        if (!this.$_.isEqual(this.selectedValue, this.value)) {
          this.selectedValue = Array.isArray(value)
            ? [...value].sort((a, b) => a.randomOrder - b.randomOrder)
            : null
        }
      },
      immediate: true
    },
    selectedValue: {
      handler(value) {
        console.log('selected value updated')
        this.$emit('input', [...value])
      }
    }
  },
  created() {
    if (!this.selectedValue?.length) {
      this.selectedValue = this.sortedValues.map((option) => option.id)
    }
  },
  methods: {
    onDragEnd() {
      this.drag = false
      this.$emit('interacted')
    },
    moveNext(value) {
      if (!this.selectedValue?.length) {
        this.selectedValue = this.sortedValues.map((option) => option.id)
      }
      const currentIndex = this.selectedValue.indexOf(value.id)
      if (currentIndex + 1 !== this.selectedValue.length) {
        this.selectedValue = [
          ...this.selectedValue.slice(0, currentIndex),
          this.selectedValue[currentIndex + 1],
          this.selectedValue[currentIndex],
          ...this.selectedValue.slice(currentIndex + 2)
        ]
      }
      this.$emit('interacted')
    },
    moveBack(value) {
      if (!this.selectedValue?.length) {
        this.selectedValue = this.sortedValues.map((option) => option.id)
      }
      const currentIndex = this.selectedValue.indexOf(value.id)
      if (currentIndex !== 0) {
        this.selectedValue = [
          ...this.selectedValue.slice(0, currentIndex - 1),
          this.selectedValue[currentIndex],
          this.selectedValue[currentIndex - 1],
          ...this.selectedValue.slice(currentIndex + 1)
        ]
      }
      this.$emit('interacted')
    }
  }
}
</script>

<style lang="scss" scoped>
.PollRankedEditorBody {
  &__list {
  }
  &__list-item {
    @apply flex items-start px-4 py-2 border border-neutral-01-25 border-b-0 bg-white cursor-pointer select-none;
    &:last-child {
      @apply border-b;
    }
  }
  &__list-number {
    @apply w-5 h-5 mt-0.5 bg-accent-01 text-caption-02 rounded-full text-white items-center justify-center flex flex-shrink-0;
  }
  &__image {
    @apply flex-shrink-0 ml-4 mr-4;
  }
  &__list-value {
    @apply ml-4 flex-1 flex;
    @screen mob {
      @apply flex-col;
      .PollRankedEditorBody__text {
        @apply mt-4;
        &:first-child {
          @apply mt-0;
        }
      }
    }
  }
  &__text {
    @apply min-w-0 break-words flex-1;
  }
  &__list-buttons {
    @apply cursor-default flex-shrink w-10 h-5 flex items-center justify-between mt-0.5 ml-4;
  }
  &__list-button {
    @apply w-4 h-4 cursor-pointer rounded-full border transition duration-200 border-primary-01-400 text-primary-01-400 flex items-center justify-center;
    &:disabled {
      @apply text-neutral-01-50 border-neutral-01-50 cursor-default;
    }
    &:not(&:disabled) {
      &:hover {
        @apply text-primary-01-200 border-primary-01-200;
      }
      &:focus {
        @apply outline-none;
      }
      &:focus-visible {
        @apply text-primary-01-200 border-primary-01-200;
      }
      &:active {
        @apply text-primary-01-600 border-primary-01-600;
      }
    }
  }
  &__list-button-icon {
    &_next {
      transform: rotate(90deg);
    }
    &_back {
      transform: rotate(-90deg);
    }
  }
}
/* base */
.flip-list {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.flip-list-move {
  transition: all 200ms ease-in-out 50ms;
}

/* appearing */
.flip-list-enter-active {
  transition: all 200ms ease-out;
}

/* disappearing */
.flip-list-leave-active {
  transition: all 200ms ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disappear to */
.flip-list-enter,
.flip-list-leave-to {
  opacity: 0;
}
</style>
