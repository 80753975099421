import { BaseModel } from '@/models/baseModel'
import { PollModel } from '@/models/poll'

export class PollResponseModel extends BaseModel {
  constructor(response = {}) {
    super()
    const { id, matrixList, choiceList, numberValue, hasDefaultValue } = response || {}

    this.id = id || null

    this.choiceList = Array.isArray(choiceList) ? [...choiceList] : []
    this.matrixList =
      matrixList?.map((row) => {
        return {
          id: row.id,
          columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
        }
      }) || []
    this.numberValue = numberValue ?? null
    this.hasDefaultValue = hasDefaultValue
  }

  static parseFromApi({ response, isMatrix }) {
    return new PollModel({
      id: response?.id,

      choiceList: !isMatrix ? response?.rows : null,
      matrixList: isMatrix ? response?.rows : null,
      numberValue: response?.value
    })
  }

  convertToApi() {
    const rows = this.choiceList?.length
      ? this.choiceList.map((choiceId, order) => ({ id: choiceId, order: order + 1 }))
      : this.matrixList?.map((row) => {
          return {
            id: row.id,
            columnIdList: row?.columnIdList?.map((columnId) => columnId) || []
          }
        }) || undefined
    const apiObject = {
      id: this.id || undefined,
      rows: rows?.length ? rows : undefined,
      value: this.numberValue || this.numberValue === 0 ? this.numberValue : undefined
    }
    return apiObject
  }

  get isEmpty() {
    return !this.choiceList?.length && !this.matrixList?.length && !this.numberValue && this.numberValue !== 0
  }

  get test() {
    return 1
  }
}
