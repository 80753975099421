<template>
  <div class="PollMultipleEditor">
    <div class="PollMultipleEditor__list">
      <div v-for="choice in sortedRows" :key="choice.id" class="PollMultipleEditor__item">
        <PollMultipleEditorItem
          v-model="localResponse.choiceList"
          :disabled="isChoiceDisabled(choice)"
          :poll="poll"
          :choice="choice"
          :locked="locked"
        ></PollMultipleEditorItem>
      </div>
    </div>
  </div>
</template>

<script>
import { PollModel } from '@/models/poll'
import PollMultipleEditorItem from '@/components/modules/poll/multiple/editor/PollMultipleEditorItem.vue'
import { PollResponseModel } from '@/models/pollResponse'

export default {
  name: 'PollMultipleEditor',
  components: { PollMultipleEditorItem },
  props: {
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    locked: { type: Boolean, default: false },
    isValid: { type: Boolean, default: false }
  },
  data() {
    return {
      localResponse: new PollResponseModel()
    }
  },
  computed: {
    isExclusiveChoiceSelected() {
      return !!this.localResponse.choiceList.find((choiceId) => this.rowsObject[choiceId]?.isExclusive)
    },
    isLocalResponseValid() {
      return !!this.localResponse.choiceList.length
    },
    sortedRows() {
      return [...(this.poll?.rows || [])]?.sort((a, b) => a.randomOrder - b.randomOrder)
    },
    rowsObject() {
      return this.poll.rows.reduce((acc, row) => ({ ...acc, [row.id]: row }), {})
    },
    selectedIdObject() {
      const selectedIdObject = {}
      this.localResponse.choiceList.forEach((choiceId) => {
        selectedIdObject[choiceId] = true
      })
      return selectedIdObject
    }
  },
  watch: {
    isExclusiveChoiceSelected: {
      handler() {
        this.localResponse.choiceList = this.localResponse.choiceList.filter(
          (choiceId) => this.rowsObject[choiceId]?.isExclusive
        )
      }
    },
    response: {
      handler() {
        if (
          !this.$_.isEqual(this.response, this.localResponse) &&
          !(this.response === null && this.localResponse.isEmpty)
        ) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      deep: true
    },
    isLocalResponseValid: {
      handler() {
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      immediate: true
    }
  },
  created() {
    this.localResponse.hasDefaultValue = false
  },
  methods: {
    isChoiceDisabled(choice) {
      return (
        (this.poll.isAnswerLimitEnabled &&
          this.localResponse.choiceList?.length >= this.poll.answerLimitAmount &&
          !this.selectedIdObject[choice.id] &&
          this.poll.answerLimitAmount > 1) ||
        (this.isExclusiveChoiceSelected && !this.selectedIdObject[choice.id])
      )
    }
  }
}
</script>

<style scoped lang="scss">
.PollMultipleEditor {
  &__item {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
  }
}
</style>
