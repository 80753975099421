<template>
  <div class="StudyNewPageBodyModals">
    <StudyNewPageUnfinished
      v-if="isUnfinishedModalVisible"
      :conversation-streams="conversationStreams"
      :study="study"
      :questions="unfinishedQuestions"
      :question-indexes="questionIndexes"
      @close="onUnfinishedModalClose"
    />
    <StudyNewPageUnanswered
      v-if="isUnansweredModalVisible"
      :conversation-streams="conversationStreams"
      :study="study"
      :questions="currentTopicUnansweredQuestions"
      :question-indexes="questionIndexes"
      @close="onUnansweredModalClose"
    />
    <StudyNewPageFinished
      v-if="isStudyCompletedModalVisible"
      :study="study"
      @close="isStudyCompletedModalVisible = false"
    />
  </div>
</template>

<script>
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { StreamState } from '@/common/enums'
import StudyNewPageUnanswered from '@/components/pages/study-new/StudyNewPageUnanswered.vue'
import StudyNewPageUnfinished from '@/components/pages/study-new/StudyNewPageUnfinished.vue'
import StudyNewPageFinished from '@/components/pages/study-new/StudyNewPageFinished.vue'
import { StudyModel } from '@/models/study'
import StudyNewPageBodyUtils from '@/components/pages/study-new/page-body/StudyNewPageBodyUtils.js'

export default {
  name: 'StudyNewPageBodyModals',
  components: {
    StudyNewPageFinished,
    StudyNewPageUnfinished,
    StudyNewPageUnanswered
  },
  props: {
    questionFetcher: { type: StudyNewPageBodyUtils, required: true },
    topics: { type: Array, default: () => [] },
    selectedTopicId: { type: String, default: null },
    conversationStreams: { type: Array, default: () => [] },
    study: { type: StudyModel, default: () => {} }
  },
  data() {
    return {
      isUnfinishedModalVisible: false,
      isUnansweredModalVisible: false,
      isStudyCompletedModalVisible: false,
      unfinishedCallback: null,
      preventStudyCompletedModal: false
    }
  },
  computed: {
    questionIndexes() {
      const questions = [...this.questions].sort((a, b) => a.order - b.order)
      const questionMap = {}
      questions.forEach((question, index) => {
        questionMap[question.id] = index
      })
      return questionMap
    },
    questions() {
      return this.questionFetcher.questions
    },
    shouldShowUnfinishedModal() {
      return (
        !this.preventStudyCompletedModal &&
        !this.studyUnansweredQuestions?.length &&
        !this.questionFetcher.isFetchingPart &&
        !this.$store.getters['studyMessages/hasSendingMessage']
      )
    },
    unfinishedQuestions() {
      return this.questions.filter((question) => {
        const storeMessage = this.$store.getters['studyMessages/savedMessage']({ questionId: question.id })
        const message = new StudyConversationMessageModel(storeMessage)
        if (message.isEmpty) {
          return false
        } else {
          return true
        }
      })
    },
    studyUnansweredQuestions() {
      const studyQuestionIdList = this.topics.reduce((result, topic) => {
        result = result.concat(topic.questionIdList)
        return result
      }, [])
      return studyQuestionIdList.filter((questionId) => {
        return this.conversationStreams.find((conversationStream) => {
          return (
            conversationStream.questionId === questionId &&
            conversationStream.state === StreamState.NOT_ANSWERED
          )
        })
      })
    },
    currentTopicUnansweredQuestions() {
      return this.questions.filter((question) => {
        return this.conversationStreams.find((conversationStream) => {
          return (
            conversationStream.questionId === question.id &&
            conversationStream.state === StreamState.NOT_ANSWERED
          )
        })
      })
    },
    conversationStreamsObject() {
      return this.conversationStreams.reduce((result, conversationStream) => {
        result[conversationStream.questionId] = conversationStream
        return result
      }, {})
    },
    questionsObject() {
      return this.questions.reduce((result, question) => {
        result[question.id] = question
        return result
      }, {})
    },
    topicsObject() {
      return this.topics.reduce((result, topic) => {
        result[topic.id] = topic
        return result
      }, {})
    }
  },
  watch: {
    // shouldShowUnfinishedModal: {
    //   handler(to, from) {
    //     if (to && !from) {
    //       this.checkUnansweredQuestionAmountFromApi().then((isCompleted) => {
    //         if (isCompleted) {
    //           this.isStudyCompletedModalVisible = true
    //           this.$nextTick(() => {
    //             this.preventStudyCompletedModal = true
    //           })
    //         }
    //       })
    //     }
    //   }
    // },
    studyUnansweredQuestions: {
      handler() {
        if (this.studyUnansweredQuestions?.length) {
          this.preventStudyCompletedModal = false
        }
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeUnload)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },
  methods: {
    async checkUnansweredQuestionAmountFromApi() {
      const { topicMeta: topicMetaList } = await this.$api.studies.getStudyMeta({ studyId: this.study.id })
      const questionIdList = topicMetaList.reduce((result, topicMeta) => {
        result = result.concat(topicMeta.questions)
        return result
      }, [])
      return !questionIdList.filter((questionId) => {
        return this.conversationStreams.find((conversationStream) => {
          return (
            conversationStream.questionId === questionId &&
            conversationStream.state === StreamState.NOT_ANSWERED
          )
        })
      })?.length
    },
    beforeUnload(e) {
      if (this.unfinishedQuestions.length || this.currentTopicUnansweredQuestions.length) {
        this.onRouteLeave()
        ;(e || window.event).returnValue = null
        return null
      }
    },
    onRouteLeave(callback) {
      if (this.unfinishedQuestions?.length) {
        this.unfinishedCallback = callback
        this.isUnfinishedModalVisible = true
      } else if (
        this.currentTopicUnansweredQuestions.length > 0 &&
        this.currentTopicUnansweredQuestions.length < this.questions.length
      ) {
        this.unfinishedCallback = callback
        this.isUnansweredModalVisible = true
      } else {
        callback && callback()
      }
    },
    onUnfinishedModalClose(params = {}) {
      this.isUnfinishedModalVisible = false
      const { withCallback } = params || {}
      if (
        withCallback &&
        this.currentTopicUnansweredQuestions.length > 0 &&
        this.currentTopicUnansweredQuestions.length < this.questions.length
      ) {
        this.isUnansweredModalVisible = true
      } else if (withCallback) {
        this.unfinishedCallback && this.unfinishedCallback()
      }
    },
    onUnansweredModalClose(params = {}) {
      this.isUnansweredModalVisible = false
      const { withCallback } = params || {}
      if (withCallback && this.currentTopicUnansweredQuestions.length) {
        this.unfinishedCallback && this.unfinishedCallback()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageBodyModals {
}
</style>
