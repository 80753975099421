<template>
  <div ref="message" class="StudyConversationMessage" :class="classList">
    <div class="StudyConversationMessage__container">
      <div
        v-if="
          elementsVisibility.header &&
          ((options.type !== StudyConversationMessageType.NEW_COMMENT && localMessage.id) ||
            isBackgroundSaving)
        "
        ref="header"
        class="StudyConversationMessage__header-wrapper"
      >
        <StudyConversationMessageHeader
          class="StudyConversationMessage__header"
          :is-mobile-layout="isMobileLayout"
          :stream-parameters="streamParameters"
          :options="options"
          :is-compact-layout="isCompactLayout"
          :message="message"
        />
        <div v-if="elementsVisibility.actions" class="StudyConversationMessage__header-actions-helper"></div>
      </div>
      <div class="StudyConversationMessage__content">
        <StudyConversationMessageBody
          v-if="!isEditMode"
          class="StudyConversationMessage__body"
          :is-mobile-layout="isMobileLayout"
          :is-compact-layout="isCompactLayout"
          :message="localMessage"
          :stream-parameters="streamParameters"
          :options="options"
          @updateMessage="onUpdateMessage"
        />
        <StudyConversationMessageEditor
          v-else
          ref="editor"
          class="StudyConversationMessage__editor"
          :is-mobile-layout="isMobileLayout"
          :is-compact-layout="isCompactLayout"
          :message.sync="localMessage"
          :stream-parameters="streamParameters"
          :options="options"
          :send-using-store="sendUsingStore"
          :is-send-pending="isSendPending"
          @updateMessage="onUpdateMessage"
          @update:message="onTempUpdateMessage"
          @cancel="onEditCancel"
          @send="send"
        />
        <div
          v-if="elementsVisibility.actions && !hasHeader"
          class="StudyConversationMessage__content-actions-helper"
        ></div>
      </div>
      <div v-if="isBackgroundSaving" class="StudyConversationMessage__actions-wrapper">
        <LLLoader />
      </div>
      <div v-else-if="elementsVisibility.actions" class="StudyConversationMessage__actions-wrapper">
        <StudyConversationMessageActions
          :message="localMessage"
          :options="options"
          :stream-parameters="streamParameters"
          :permissions="permissions"
          @edit="onEdit"
          @updateMessage="onUpdateMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidV4 } from 'uuid'
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'
import StudyConversationMessageActions from '@/components/modules/study/study-conversation-message/StudyConversationMessageActions.vue'
import StudyConversationMessageHeader from '@/components/modules/study/study-conversation-message/StudyConversationMessageHeader.vue'
import StudyConversationMessageEditor from '@/components/modules/study/study-conversation-message/StudyConversationMessageEditor.vue'
import StudyConversationMessageBody from '@/components/modules/study/study-conversation-message/StudyConversationMessageBody.vue'
import LLLoader from '@/components/common/LLLoader.vue'
import { ConversationMessageFileModel } from '@/models/conversationMessageFile'
import { PollType } from '@/common/enums'
import studyNextQuestionScroller from '@/utils/modules/studyNextQuestionScroller'
import { PollResponseModel } from '@/models/pollResponse'

export default {
  name: 'StudyConversationMessage',
  components: {
    LLLoader,
    StudyConversationMessageActions,
    StudyConversationMessageHeader,
    StudyConversationMessageEditor,
    StudyConversationMessageBody
  },
  props: {
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, default: null },
    streamParameters: { type: StudyConversationMessageStreamParametersModel, required: true },
    compact: { type: Boolean, default: false },
    sendUsingStore: { type: Boolean, default: false }
  },
  data() {
    return {
      resizeObserver: null,
      localMessage: new StudyConversationMessageModel(),
      currentWidth: null,
      isEditMode: false,
      storeMessageUid: null,
      hasHeader: false,
      isSendPending: false
    }
  },
  computed: {
    isBackgroundSaving() {
      return this.message?.isSaving
    },
    elementsVisibility() {
      return {
        actions: !this.isEditMode && (this.permissions.remove || this.permissions.edit),
        header:
          ((this.options.type !== StudyConversationMessageType.ANSWER && this.message) ||
            this.options.type !== StudyConversationMessageType.NEW_COMMENT) &&
          this.options.elementsVisibility.header
      }
    },
    permissions() {
      const reasonsToRemove = {
        isModeratorMessage: !this.message?.isOwnerUser
      }
      const reasonsToEdit = {
        isModeratorMessage: !this.message?.isOwnerUser
      }
      return {
        remove: Object.values(reasonsToRemove).includes(true) && this.options.allowToRemove,
        edit: Object.values(reasonsToEdit).includes(true) && this.options.allowToEdit
      }
    },
    classList() {
      return {
        [`${this.$options.name}_mobile`]: this.isMobileLayout,
        [`${this.$options.name}_compact`]: this.isCompactLayout,
        [`${this.$options.name}_owner-user`]:
          this.localMessage.isOwnerUser && this.options.type !== StudyConversationMessageType.ANSWER,
        [`${this.$options.name}_additional`]: this.localMessage.isAdditionalQuestions && !this.isEditMode
      }
    },
    isCompactLayout() {
      return this.compact
    },
    isMobileLayout() {
      return !this.compact && this.currentWidth < 540
    },
    StudyConversationMessageType() {
      return StudyConversationMessageType
    }
  },
  watch: {
    message: {
      handler() {
        if (!this.message) {
          this.init()
        } else if (
          this.message.isTemporary &&
          this.message.messageOptionsType &&
          this.message.messageOptionsType !== this.options.type
        ) {
          this.init()
          this.$store.commit('studyMessages/REMOVE_MESSAGE', {
            questionId: this.message.questionId
          })
        } else if (!this.$_.isEqual(this.message, this.localMessage)) {
          this.localMessage = new StudyConversationMessageModel(this.message)
          this.localMessage.messageOptionsType = this.options.type
          if (this.options.type === StudyConversationMessageType.SCREENING) {
            this.isEditMode = true
          }
          if (
            this.options.type === StudyConversationMessageType.NEW_COMMENT ||
            this.options.type === StudyConversationMessageType.COMMENT
          ) {
            this.localMessage.pollResponse = new PollResponseModel()
          }
          this.localMessage.files = this.localMessage.files.filter(
            (file) => !file.isTemporary || file?.temporaryData?.browserFile?.name
          )
        }
      },
      deep: true,
      immediate: true
    },
    localMessage: {
      handler() {
        /*if (this.localMessage.id) {
          this.$emit('update:message', new StudyConversationMessageModel(this.localMessage))
        }*/
      },
      deep: true
    },
    isBackgroundSaving: {
      handler(to, from) {
        if (to) {
          this.isEditMode = false
        } else if (from && !to) {
          this.isEditMode = true
        }
      },
      immediate: true
    }
  },
  created() {
    if (this.message?.isTemporary) {
      this.initTemporaryMessage()
    }
  },
  mounted() {
    this.checkWidth()
    this.initResiseListeners()
  },
  beforeDestroy() {
    this.destroyResiseListeners()
    //this.removeMessageFromStore()
  },
  methods: {
    async send(params = {}) {
      const { throwError } = params || {}
      if (!this.$refs.editor.isValid) {
        throw new Error('Message is not valid')
      }
      if (
        this.streamParameters.question?.poll?.type === PollType.RANKED_LIST &&
        !this.localMessage?.pollResponse?.choiceList?.length &&
        (this.options.type === StudyConversationMessageType.ANSWER ||
          this.options.type === StudyConversationMessageType.SCREENING)
      ) {
        this.$refs?.editor?.$refs?.poll?.$refs?.['ranked-poll']?.onConfirm &&
          this.$refs?.editor?.$refs?.poll?.$refs?.['ranked-poll']?.onConfirm()
        return
      }
      try {
        let message
        const pollType =
          (this.options.type === StudyConversationMessageType.ANSWER &&
            this.streamParameters?.question?.poll?.type) ||
          null
        if (this.sendUsingStore && !this.localMessage.id) {
          this.$store.commit('studyMessages/SET_MESSAGE_SAVING_STATE', {
            questionId: this.localMessage.questionId,
            value: true
          })
          let filePromises = []
          this.localMessage.files.forEach((file) => {
            if (!file?.isTemporary) {
              return
            }
            filePromises.push(
              this.$store
                .dispatch('fileUpload/upload', {
                  uuid: file.id,
                  file: file.temporaryData.browserFile,
                  uploadingUrl: file.uploadingInfo.url,
                  uploadingFields: file.uploadingInfo.fields
                })
                .then((updatedFile) => {
                  this.localMessage.files[this.localMessage.files.indexOf(file)] =
                    ConversationMessageFileModel.parseFromApi({
                      file: updatedFile,
                      prospectId: this.streamParameters.prospect.id,
                      questionId: this.streamParameters.question.id,
                      studyId: this.streamParameters.studyId
                    })
                })
            )
          })
          try {
            await Promise.all(filePromises)
          } catch (e) {
            throw e
          } finally {
            this.$store.commit(
              'fileUpload/REMOVE_FILE_DATA',
              this.localMessage.files.map((file) => file?.id)
            )
          }
          const { conversationMessage } = await this.$store.dispatch('studyMessages/sendMessage', {
            message: this.localMessage
          })
          message = conversationMessage
        } else {
          if (this.isSendPending) return
          this.isSendPending = true
          const { studyId, questionId, prospectId } = this.localMessage
          const params = { studyId, questionId, prospectId, messageId: this.message?.id }

          const apiMethod = this.message.id
            ? this.$api.studies.updateConversationStream
            : this.$api.studies.sendConversationStream

          const { conversationMessage } = await apiMethod({
            ...params,
            message: this.localMessage.convertToApi()
          })
          message = conversationMessage
        }

        this.localMessage = StudyConversationMessageModel.parseFromApi({
          message,
          questionId: this.streamParameters.question.id,
          studyId: this.streamParameters.studyId,
          prospectId: this.localMessage.prospectId,
          pollType
        })
        if (this.options.type === StudyConversationMessageType.ANSWER) {
          studyNextQuestionScroller.emit({ questionId: this.localMessage.questionId })
        }
        this.onUpdateMessage({ message: new StudyConversationMessageModel(this.localMessage) })
        if (!throwError) {
          this.$notify({
            group: 'main',
            type: 'success',
            title: this.$t('study_conversation_message.notifications.message_sent')
          })
        }
      } catch (e) {
        this.$store.commit('studyMessages/SET_MESSAGE_SAVING_STATE', {
          questionId: this.localMessage.questionId,
          value: false
        })
        if (throwError) {
          throw e
          return
        } else {
          this.$notify({
            group: 'main',
            type: 'error',
            title: 'Error',
            text: this.$getErrorMessage(e)
          })
        }
      } finally {
        this.isSendPending = false
      }
    },
    onTempUpdateMessage(message) {
      this.$emit('updateTempMessage', { message })
    },
    checkContent() {
      this.$nextTick(() => {
        this.hasHeader = !!this.$refs.header?.$el?.children
      })
    },
    onUpdateMessage({ message }) {
      if (!this.message?.id) {
        this.$emit('create', { message })
        if (this.options.type !== StudyConversationMessageType.ANSWER) {
          this.init()
          return
        }
      }
      this.localMessage = new StudyConversationMessageModel(message)
      this.$emit('update:message', new StudyConversationMessageModel(this.localMessage))
      this.isEditMode = false
    },
    onEdit() {
      this.isEditMode = true
    },
    onEditCancel() {
      this.localMessage = new StudyConversationMessageModel(this.message)
      this.isEditMode = false
    },
    initTemporaryMessage() {
      if (!this.isBackgroundSaving) {
        this.isEditMode = true
      }
    },
    init() {
      const hasDefaultValue = !!(
        this.options.type === StudyConversationMessageType.ANSWER &&
        this.streamParameters.question?.poll?.type
      )
      console.log(hasDefaultValue)
      this.isEditMode =
        this.options.type === StudyConversationMessageType.NEW_COMMENT ||
        (this.options.type === StudyConversationMessageType.ANSWER && !this.message?.id) ||
        this.options.type === StudyConversationMessageType.SCREENING
      this.localMessage = new StudyConversationMessageModel({
        questionId: this.streamParameters.question?.id,
        prospectId: this.$store.getters['user/userObject'].id,
        studyId: this.streamParameters.studyId,
        prospect: this.$store.getters['user/userObject'],
        pollType: this.streamParameters.question?.poll?.type,
        pollResponse: new PollResponseModel({ hasDefaultValue })
      })
    },
    checkWidth() {
      this.currentWidth = this.$refs.message.clientWidth
    },
    initResiseListeners() {
      window.addEventListener('resize', this.checkWidth)
      this.resizeObserver = new ResizeObserver(this.checkWidth)
      this.resizeObserver.observe(this.$el)
    },
    destroyResiseListeners() {
      window.removeEventListener('resize', this.checkWidth)
      if (this.resizeObserver?.disconnect) this.resizeObserver.disconnect()
    },
    // TODO: remove this in notebook refactor
    insertMessageToStore() {
      if (!this.message) {
        return
      }
      const uid = uuidV4()
      this.$store.commit('ConversationMessages/ADD_MESSAGE', {
        uid,
        questionId: this.message.questionId,
        participantId: this.message.prospectId,
        studyId: this.message.studyId,
        message: this.message
      })
      this.storeMessageUid = uid
    },
    removeMessageFromStore() {
      if (!this.message) {
        return
      }
      this.$store.commit('ConversationMessages/REMOVE_MESSAGE', this.storeMessageUid)
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessage {
  $root: &;
  @apply flex flex-col relative;
  &__actions-wrapper {
    @apply right-0 top-0 absolute;
  }
  &__header-wrapper {
    @apply flex items-start min-w-0;
  }
  &__container {
    @apply relative w-full;
  }
  &__header {
    @apply flex-1  min-w-0;
  }
  &__content {
    @apply flex mt-2;
    &:first-child {
      @apply mt-0;
    }
  }
  &__header-actions-helper {
    @apply w-7 flex-shrink-0 ml-4;
  }
  &__content-actions-helper {
    @apply w-7 flex-shrink-0 ml-4;
  }
  &__body {
    @apply w-full min-w-0;
  }
  &__editor {
    @apply w-full min-w-0;
  }
  &_owner-user {
    @apply pl-7 rounded ml-6;
    &:before {
      @apply w-1 h-full absolute  top-0 left-0;
      background: #8274ec;
      content: '';
    }
    &#{$root}_mobile,
    &#{$root}_compact {
      @apply ml-4;
    }
    &#{$root}_additional {
      &:before {
        @apply bg-accent-01-400;
      }
    }
  }
  &__indicators {
    @apply absolute top-0 left-0 ml-4 -mt-3 flex;
  }

  &.message-fade-enter-active,
  &.message-fade-leave-active {
    transition-property: opacity, transform;
    transition-duration: 0.3s;
  }
  &.message-fade-enter,
  &.message-fade-leave-to {
    opacity: 0;
  }

  &.message-fade-enter {
    transform: scale(0.8);
  }

  &.message-fade-leave-to {
    transform: scale(0.8);
  }
}
</style>
