<template>
  <div class="ForumChatPoll" :class="{ 'ForumChatPoll_no-padding': isResultsShown || poll.isResponded }">
    <div class="ForumChatPoll__content">
      <ForumPollMultiple
        v-if="poll.type === PollTypeEnum.MULTIPLE_CHOICE"
        ref="module"
        :response.sync="localResponse"
        :poll="poll"
        :thread="thread"
        :show-results="isResultsShown"
        :is-valid.sync="isValid"
      />
      <ForumPollMatrix
        v-else-if="poll.type === PollTypeEnum.MATRIX"
        ref="module"
        :poll="poll"
        :thread="thread"
        :response.sync="localResponse"
        :show-results="isResultsShown"
        :is-valid.sync="isValid"
      ></ForumPollMatrix>
      <ForumPollNumber
        v-else-if="poll.type === PollTypeEnum.NUMBER_ENTRY"
        ref="module"
        :poll="poll"
        :thread="thread"
        :response.sync="localResponse"
        :show-results="isResultsShown"
        :is-valid.sync="isValid"
      ></ForumPollNumber>
      <ForumPollRanked
        v-else-if="poll.type === PollTypeEnum.RANKED_LIST"
        ref="module"
        :poll="poll"
        :thread="thread"
        :response.sync="localResponse"
        :show-results="isResultsShown"
        :is-valid.sync="isValid"
        @confirm-send="send"
      ></ForumPollRanked>
      <div v-if="!poll.isResponded && !isResultsShown" class="ForumChatPoll__footer">
        <LLButton
          v-if="poll.type !== PollTypeEnum.RANKED_LIST"
          class=""
          :disabled="!isValid"
          small
          :loading="pending"
          @click="send"
        >
          {{ $t('button_submit') }}
        </LLButton>
        <LLButton
          v-if="poll.type === PollTypeEnum.RANKED_LIST"
          class=""
          :disabled="!isValid"
          small
          :loading="pending"
          @click="tryToSendThroughRankedList"
        >
          {{ $t('button_submit') }}
        </LLButton>

        <LLButton
          v-if="isAbleToSeeResults"
          class="ForumPoll__show-results-button"
          tertiary
          small
          @click="showResults"
        >
          {{ $t('forum.poll.go_to_results') }}
        </LLButton>
      </div>
    </div>
    <div v-if="isResultsShownForced" class="ForumChatPoll__bottom-actions">
      <LLButton
        class="ForumChatPoll__bottom-action ForumChatPollBackButton"
        tertiary
        small
        @click="goBackToVote"
      >
        <template #icon-left> <ArrowMiniIcon class="ForumChatPollBackButton__arrow" /> </template>
        {{ $t('forum.poll.back_to_vote') }}
      </LLButton>
      <LLButton
        v-if="false"
        class="ForumChatPoll__bottom-action ForumChatPoll__export-button"
        tertiary
        small
        @click="exportChart"
      >
        <template #icon-left> <CSVIcon /> </template>
        <template #default>Export Chart</template>
      </LLButton>
    </div>
  </div>
</template>

<script>
import ForumPollMultiple from '@/components/modules/forum-chat/poll/ForumPollMultiple.vue'
import { PollType } from '@/common/enums'
import ForumPollMatrix from '@/components/modules/forum-chat/poll/ForumPollMatrix.vue'
import ForumPollNumber from '@/components/modules/forum-chat/poll/ForumPollNumber.vue'
import ForumPollRanked from '@/components/modules/forum-chat/poll/ForumPollRanked.vue'
import { PollResponseModel } from '@/models/pollResponse'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import CSVIcon from '@/assets/icons/CSVIcon.vue'
import ArrowMiniIcon from '@/assets/icons/ArrowMiniIcon.vue'
import { ForumPollModel } from '@/models/forum/forumPoll'
import { ForumThreadModel } from '@/models/forum/forumThread'

export default {
  name: 'ForumChatPoll',
  components: {
    ArrowMiniIcon,
    CSVIcon,
    LLButton,
    ForumPollRanked,
    ForumPollNumber,
    ForumPollMatrix,
    ForumPollMultiple
  },
  props: {
    thread: { type: ForumThreadModel, required: true },
    poll: { type: ForumPollModel, required: true },
    isAbleToVote: { type: Boolean, default: true }
  },
  data() {
    return {
      localResponse: new PollResponseModel({ hasDefaultValue: true }),
      pending: false,
      isValid: false,
      isResultsShownForced: false
    }
  },
  computed: {
    isResultsShown() {
      return !this.isAbleToVote || this.isResultsShownForced
    },
    PollTypeEnum() {
      return PollType
    },
    isAbleToSeeResults() {
      return this.poll.prospectId === this.$store.state.user.user.id
    }
  },
  methods: {
    tryToSendThroughRankedList() {
      this.$refs.module.send()
    },
    showResults() {
      this.isResultsShownForced = true
    },
    goBackToVote() {
      this.isResultsShownForced = false
    },
    async send() {
      this.pending = true
      try {
        await this.$api.forum.poll.createResult({
          chatId: this.poll.chatId,
          threadId: this.poll.threadId,
          pollResponse: this.localResponse.convertToApi()
        })
        this.$emit('update', { poll: new ForumPollModel({ ...this.poll, isResponded: true }) })
        this.$emit('vote')
      } catch (e) {
        console.log(e)
        this.$toast.error(this.$getErrorMessage(e))
      } finally {
        this.pending = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ForumChatPoll {
  $root: &;
  @apply border border-neutral-01-50 rounded;
  &__content {
    @apply p-4;
    @screen lg {
      @apply p-6;
    }
  }
  &_no-padding {
    #{$root}__content {
      @apply p-0;
    }
  }
  &__footer {
    @apply flex flex-col mt-6;
    @screen md {
      @apply flex-row justify-between;
    }
  }
  &__bottom-actions {
    @apply flex flex-col border-t border-neutral-01-50 px-2 py-2;
    @screen md {
      @apply flex-row justify-between;
    }
  }
  &__export-button {
    @apply ml-auto;
  }
  &BackButton {
    &__arrow {
      @apply rotate-180 transform;
    }
  }
  &__bottom-action {
  }
  &__show-results-button {
    @apply mt-4;
    @screen md {
      @apply ml-auto mt-0;
    }
  }
}
</style>
