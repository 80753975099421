<template>
  <div class="PollNumberEditorSlider">
    <QuestionSlider
      v-model="localResponse.numberValue"
      :max="poll.sliderTo"
      :min="poll.sliderFrom"
      :str-max="poll.sliderToLabel"
      :str-min="poll.sliderFromLabel"
      :locked="locked"
    ></QuestionSlider>
  </div>
</template>

<script>
import { PollResponseModel } from '@/models/pollResponse'
import { PollNumberStyle, PollType } from '@/common/enums'
import { PollModel } from '@/models/poll'
import QuestionSlider from '@/pages/Conversation/Question/QuestionAnswer/QuestionSlider.vue'

export default {
  name: 'PollNumberEditorSlider',
  components: { QuestionSlider },
  props: {
    poll: { type: PollModel, required: true },
    response: { type: PollResponseModel, required: true },
    locked: { type: Boolean, default: false },
    isFirstTimeFill: { type: Boolean, default: true }
  },
  data() {
    return {
      localResponse: new PollResponseModel(),
      isValid: false,
      isInitDone: false
    }
  },
  computed: {
    PollTypeEnum() {
      return PollType
    },
    PollStyleEnum() {
      return PollNumberStyle
    },
    isLocalResponseValid() {
      return this.localResponse.numberValue || this.localResponse.numberValue === 0
    }
  },
  watch: {
    'localResponse.numberValue': {
      handler() {
        if (this.isInitDone) {
          this.localResponse.hasDefaultValue = false
        }
      },
      immediate: true
    },
    response: {
      handler() {
        if (!this.$_.isEqual(this.response, this.localResponse)) {
          this.localResponse = new PollResponseModel(this.response)
        }
      },
      deep: true,
      immediate: true
    },
    localResponse: {
      handler() {
        this.$emit('update:response', new PollResponseModel(this.localResponse))
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      deep: true
    },
    isLocalResponseValid: {
      handler() {
        this.$emit('update:isValid', this.isLocalResponseValid)
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isInitDone = true
    })
  },
  methods: {
    onInteract(value) {
      if (!value) return
      this.localResponse.hasDefaultValue = false
    }
  }
}
</script>

<style scoped lang="scss">
.PollNumberEditorSlider {
}
</style>
