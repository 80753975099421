<template>
  <LLConfirmModal class="StudyNewPageUnfinishedDefaultValue">
    <template #title>
      {{ $t('conversation-leave-warning_default-value_title') }}
    </template>
    <template #default>
      <div class="StudyNewPageUnfinishedDefaultValue__content">
        <div class="StudyNewPageUnfinishedDefaultValue__top-description">
          {{ $t('conversation-leave-warning_default-value_description') }}
        </div>
        <div
          v-for="question in localUnsavedQuestions"
          :key="question.id"
          class="StudyNewPageUnfinishedDefaultValue__question"
        >
          <div v-if="messageState(question.id)" class="StudyNewPageUnfinishedDefaultValue__question-icon">
            <LoadingIcon
              v-if="messageState(question.id) === MessageSendingStates.SENDING"
              class="StudyNewPageUnfinishedDefaultValue__question-icon-loading"
            ></LoadingIcon>
            <ClearIcon
              v-else-if="messageState(question.id) === MessageSendingStates.ERROR"
              class="StudyNewPageUnfinishedDefaultValue__question-icon-warning"
            ></ClearIcon>
            <SuccessIcon
              v-else-if="messageState(question.id) === MessageSendingStates.SUCCESS"
              class="StudyNewPageUnfinishedDefaultValue__question-icon-success"
            ></SuccessIcon>
            <ClearIcon
              v-else-if="messageState(question.id) === MessageSendingStates.HAS_DEFAULT_VALUE"
              class="StudyNewPageUnfinishedDefaultValue__question-icon-has-default-value"
            ></ClearIcon>
          </div>
          <div class="StudyNewPageUnfinishedDefaultValue__question-number">
            {{
              $t('conversation-leave-warning_question-number', { questionNumber: questionIndex(question.id) })
            }}
          </div>
          <div class="StudyNewPageUnfinishedDefaultValue__question-title">{{ question.title }}</div>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="StudyNewPageUnfinishedDefaultValue__actions">
        <LLButton
          class="StudyNewPageUnfinishedDefaultValue__action"
          green
          :disabled="isPending"
          small
          @click.native="submitClick"
        >
          {{ $t('conversation-leave-warning_action_submit') }}
        </LLButton>
        <LLButton
          class="StudyNewPageUnfinishedDefaultValue__action"
          :disabled="isPending"
          small
          @click.native="cancelClick"
          >{{ $t('conversation-leave-warning_action-cancel') }}</LLButton
        >
      </div>
    </template>
  </LLConfirmModal>
</template>

<script>
import LLButton from '@/components/common/ui-components/LLButton.vue'
import LoadingIcon from '@/components/common/LoadingIcon.vue'
import LLConfirmModal from '@/components/common/LLConfirmModal.vue'
import SuccessIcon from '@/assets/icons/SuccessIcon.vue'
import ClearIcon from '@/assets/icons/ClearIcon.vue'
import MessageSendingStates from '@/components/pages/study-new/unfinished/messageSendingStates.json'

export default {
  name: 'StudyNewPageUnfinishedDefaultValue',
  components: { SuccessIcon, ClearIcon, LoadingIcon, LLConfirmModal, LLButton },
  props: {
    sendingStates: { type: Object, default: () => {} },
    questions: { type: Array, required: true, default: () => [] },
    isPending: { type: Boolean, default: false },
    questionIndexes: { type: Object, default: () => {} }
  },
  data() {
    return {}
  },
  computed: {
    MessageSendingStates() {
      return MessageSendingStates
    },
    localUnsavedQuestions() {
      return this.questions.filter(
        (question) =>
          this.sendingStates?.[question.id] === MessageSendingStates.HAS_DEFAULT_VALUE ||
          this.sendingStates?.[question.id] === MessageSendingStates.SENDING
      )
    }
  },
  methods: {
    questionIndex(questionId) {
      return this.questionIndexes[questionId] !== undefined ? this.questionIndexes[questionId] + 1 : 0
    },
    cancelClick() {
      this.$emit('cancel')
    },
    submitClick() {
      this.$emit('submit')
    },
    notNowClick() {
      this.$emit('skip')
    },
    messageState(questionId) {
      return this.sendingStates?.[questionId]
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageUnfinishedDefaultValue {
  &__top-description {
    @apply mb-5;
  }
  &__bottom-description {
    @apply mt-5;
  }
  &__content {
    max-width: 500px;
  }

  &__actions {
    @apply flex;
    @screen mob-only {
      @apply flex-col items-end;
    }
  }
  &__action {
    @apply ml-4;
    &:first-child {
      @apply ml-0;
    }
    @screen mob-only {
      @apply ml-0 mt-2;
      &:first-child {
        @apply mt-0;
      }
    }
  }
  &__question {
    @apply flex items-center min-w-0;
    &-icon {
      @apply w-5 h-5 mr-3 flex-shrink-0;
      &-loading {
        @apply w-5 h-5;
      }
      &-warning {
        @apply w-full h-full text-status-03-600;
      }
      &-has-default-value {
        @apply w-full h-full text-warning-01;
      }
      &-success {
        @apply w-full h-full text-status-01-600;
      }
    }
    &-number {
      @apply mr-2 text-button-01;
    }
    &-title {
      @apply flex-1 truncate;
    }
  }
}
</style>
