<template>
  <div class="QuestionSlider">
    <LLRangeSlider
      :value="value"
      :disabled="locked"
      :min="min"
      :str-max="strMax"
      :str-min="strMin"
      :max="max"
      @input="onInput"
      @update:interacted="onInteracted"
    />
  </div>
</template>

<script>
import LLRangeSlider from '@/components/common/LLRangeSlider'

export default {
  name: 'QuestionSlider',
  components: { LLRangeSlider },
  props: {
    value: { type: Number, default: null },
    min: { type: Number, default: 1 },
    max: { type: Number, default: 10 },
    locked: { type: Boolean, default: false },
    strMin: { type: String, default: '' },
    strMax: { type: String, default: '' }
  },
  data() {
    return {}
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onInteracted(value) {
      console.log('value', value)
      this.$emit('update:interacted', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.QuestionSlider {
}
</style>
