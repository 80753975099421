<template>
  <div class="StudyConversationMessageEditor">
    <StudyConversationMessagePoll
      v-if="
        streamParameters.question.poll &&
        (options.type === StudyConversationMessageType.ANSWER ||
          options.type === StudyConversationMessageType.SCREENING)
      "
      ref="poll"
      :key="localMessage.id"
      class="StudyConversationMessageEditor__poll"
      :question="streamParameters.question"
      :message.sync="localMessage"
      :options="options"
      :is-compact-layout="isCompactLayout"
      :is-mobile-layout="isMobileLayout"
      @rankedConfirm="$emit('send')"
      @update:isValid="onIsPollValidUpdate"
    />
    <ValidationObserver
      v-if="isTextVisible"
      ref="validation-observer"
      class="StudyConversationMessageEditor__text"
    >
      <StudyConversationMessageEditorText
        :question="streamParameters.question"
        :message.sync="localMessage"
        :options="options"
        :is-compact-layout="isCompactLayout"
        :is-mobile-layout="isMobileLayout"
        :is-valid="isValid"
        :is-send-pending="isSendPending"
        :is-able-to-cancel="isAbleToCancel"
        @sendClick="onSendClick"
        @cancelClick="onCancelClick"
      />
    </ValidationObserver>
    <div
      v-if="options.elementsVisibility.actions && (isSendButtonVisible || isCancelButtonVisible)"
      class="StudyConversationMessageEditor__footer"
    >
      <div class="StudyConversationMessageEditor__actions">
        <LLButton
          v-if="isSendButtonVisible"
          small
          :loading="isSendPending"
          :disabled="!isValid"
          primary
          class="StudyConversationMessageEditor__action"
          @click="onSendClick"
        >
          <template v-if="message.id">{{ $t('button_save') }}</template>
          <template v-else>{{ $t('button_send') }}</template>
        </LLButton>
        <LLButton
          v-if="isCancelButtonVisible"
          class="StudyConversationMessageEditor__action"
          small
          secondary
          :disabled="isSendPending"
          @click="onCancelClick"
          >{{ $t('button_cancel') }}</LLButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  StudyConversationMessageOptionsModel,
  StudyConversationMessageType
} from '@/models/studyConversationMessage/studyConversationMessageOptions'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import LLButton from '@/components/common/ui-components/LLButton.vue'
import { screenCatcher } from '@/mixins/screenCatcher'
import { StudyConversationMessageStreamParametersModel } from '@/models/studyConversationMessage/studyConversationMessageStreamParameters'
import StudyConversationMessagePoll from '@/components/modules/study/study-conversation-message/StudyConversationMessagePoll.vue'
import StudyConversationMessageEditorText from '@/components/modules/study/study-conversation-message/editor/StudyConversationMessageEditorText.vue'
import { PollType } from '@/common/enums'

export default {
  name: 'StudyConversationMessageEditor',
  components: {
    LLButton,
    StudyConversationMessageEditorText,
    StudyConversationMessagePoll,
    ValidationObserver
  },
  mixins: [screenCatcher],
  props: {
    isCompactLayout: { type: Boolean, default: false },
    isMobileLayout: { type: Boolean, default: false },
    options: {
      type: StudyConversationMessageOptionsModel,
      default: () => new StudyConversationMessageOptionsModel()
    },
    message: { type: StudyConversationMessageModel, required: true },
    streamParameters: { type: StudyConversationMessageStreamParametersModel, required: true },
    sendUsingStore: { type: Boolean, default: false },
    isSendPending: { type: Boolean, default: false }
  },
  data() {
    return {
      localMessage: null,
      isPollValid: false,
      isTextValid: false
    }
  },
  computed: {
    isAbleToCancel() {
      return !(
        this.options.type === StudyConversationMessageType.NEW_COMMENT ||
        (this.options.type === StudyConversationMessageType.ANSWER && !this.message?.id)
      )
    },
    isSendButtonVisible() {
      return (
        this.options.type === StudyConversationMessageType.ANSWER &&
        this.streamParameters.question?.poll &&
        !this.streamParameters.question?.requireTextOrMediaResponse
      )
    },
    isCancelButtonVisible() {
      return (
        this.isAbleToCancel &&
        this.options.type === StudyConversationMessageType.ANSWER &&
        this.streamParameters.question?.poll &&
        !this.streamParameters.question?.requireTextOrMediaResponse
      )
    },
    StudyConversationMessageType() {
      return StudyConversationMessageType
    },
    isTextVisible() {
      return (
        (this.options.type === StudyConversationMessageType.SCREENING &&
          !this.streamParameters.question.poll) ||
        this.options.type === StudyConversationMessageType.COMMENT ||
        this.options.type === StudyConversationMessageType.NEW_COMMENT ||
        (this.options.type === StudyConversationMessageType.ANSWER &&
          this.streamParameters.question.requireTextOrMediaResponse) ||
        (this.options.type === StudyConversationMessageType.SCREENING &&
          this.streamParameters.question.requireTextOrMediaResponse) ||
        (this.options.type === StudyConversationMessageType.ANSWER && !this.streamParameters.question.poll)
      )
    },
    isValid() {
      return (
        ((this.options.type !== StudyConversationMessageType.ANSWER &&
          this.options.type !== StudyConversationMessageType.SCREENING) ||
          !this.streamParameters.question.poll ||
          this.isPollValid) &&
        (!this.isTextVisible || this.isTextValid)
      )
    }
  },
  watch: {
    message: {
      handler() {
        if (!this.$_.isEqual(this.message, this.localMessage)) {
          this.localMessage = new StudyConversationMessageModel(this.message)
        }
      },
      deep: true,
      immediate: true
    },
    localMessage: {
      handler() {
        this.validate()
        if (!this.message.id && this.localMessage.questionId && this.sendUsingStore) {
          this.saveMessage()
        }
        this.$emit('update:message', new StudyConversationMessageModel(this.localMessage))
      },
      deep: true
    },
    isValid: {
      handler() {
        this.localMessage.isValid = this.isValid
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.validate()
    })
  },
  methods: {
    saveMessage() {
      this.localMessage.isTemporary = true
      this.$store.commit('studyMessages/SET_MESSAGE', {
        message: new StudyConversationMessageModel(this.localMessage)
      })
    },
    onIsPollValidUpdate(value) {
      this.isPollValid = value
    },
    onSendClick() {
      if (
        this.streamParameters.question?.poll?.type === PollType.RANKED_LIST &&
        (this.options.type === StudyConversationMessageType.ANSWER ||
          this.options.type === StudyConversationMessageType.SCREENING)
      ) {
        this.$refs['poll'].sendRanked()
      } else {
        this.$emit('send')
      }
    },
    onCancelClick() {
      this.$emit('cancel')
    },
    async validate() {
      const validationObserver = this.$refs['validation-observer']
      if (validationObserver) {
        const validation = await validationObserver.validateWithInfo({ silent: true })
        this.isTextValid = validation.flags.valid || !!this.localMessage?.files?.length
        return
      }
      this.isTextValid = false
    }
  }
}
</script>

<style scoped lang="scss">
.StudyConversationMessageEditor {
  @apply flex flex-col;
  &__poll {
  }
  &__text {
    @apply mt-2;
    &:first-child {
      @apply mt-0;
    }
  }
  &__actions {
    @apply flex justify-start;
    @apply mt-4;
    &:first-child {
      @apply mt-0;
    }
  }
  &__action {
    @apply ml-2;
    &:first-child {
      @apply ml-0;
    }
  }
  &__footer {
    @apply mt-4 flex flex-col;
  }
}
</style>
